import './App.css';
import { Route, Routes } from 'react-router-dom';
import Protected from './protected';
import Login from './authentication/login';
import SignUp from './authentication/signup';
import Home from './routes/home';
import './assets/css/responsive.css'
import Example from './toast';
import CategoryRoute from './routes/categoriesRoute';
import Shop from './routes/shop';
import ShopByCategory from './routes/shopByCategory';
import ProductSingle from './routes/productSingle';
import Cart from './routes/cart';
import Profile from './routes/dashboard/profile';
import Address from './routes/dashboard/address';
import OrderJsx from './routes/dashboard/order';
import NavContainerRouter from './navContainerRoute';
import ContactUs from './routes/contact';
import AboutUs from './routes/about';

function App() {
  return (
    <Routes >
      <Route path='/' element={<NavContainerRouter />}>
        <Route path='/' element={<Home />}></Route>
        <Route path='/shop' element={<Shop />}></Route>
        <Route path='/shop/:categoryId' element={<ShopByCategory />}></Route>
        <Route path='/product/:productId' element={<ProductSingle />}></Route>
        <Route path='/category' element={<CategoryRoute />}></Route>
        <Route path='contact-us' element={<ContactUs />}></Route>
        <Route path='about-us' element={<AboutUs />}></Route>


        <Route path='/toast' element={<Example />}></Route>
      </Route>
      <Route path='/' element={<Protected></Protected>}>
        <Route path='/cart' element={<Cart />}></Route>
        <Route path='/account' element={<Profile />}></Route>
        <Route path='/account/address' element={<Address />}></Route>
        <Route path='/account/orders' element={<OrderJsx />}></Route>

      </Route>
      <Route path='/login' element={<Login />}></Route>
      <Route path='/signup' element={<SignUp />}></Route>
      <Route path='*' element={<h1>Page Not Found - Designing</h1>}></Route>
    </Routes>
  );
}

export default App;
