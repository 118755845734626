import React, { useState, useEffect } from 'react';
import './../route.css';
import DashboardLayout from './dashboardLayout';
import { auth, storage, db } from '../../config/firebase.config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import { collection, query, where, getDocs, setDoc } from 'firebase/firestore';

const Profile = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [altPhoneNumber, setAltPhoneNumber] = useState('');
    const [gstin, setGstin] = useState('');
    const [businessName, setBusinessName] = useState('');

    useEffect(() => {
        if (auth.currentUser) {
            const nameParts = auth.currentUser.displayName ? auth.currentUser.displayName.split(' ') : ['', ''];
            setFirstName(nameParts[0]);
            setLastName(nameParts[1]);
            setPhoneNumber(auth.currentUser.phoneNumber || '');
        }

        const fetchProfileData = async () => {
            try {
                const userProfileQuery = query(
                    collection(db, 'userProfile'),
                    where('uid', '==', auth.currentUser.uid)
                );
                const querySnapshot = await getDocs(userProfileQuery);

                querySnapshot.forEach((docSnapshot) => {
                    const data = docSnapshot.data();
                    setAltPhoneNumber(data.altPhoneNumber || '');
                    setGstin(data.gstin || '');
                    setBusinessName(data.businessName || '');
                    setPhoneNumber(data.phoneNumber || '')

                });
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchProfileData();
    }, []);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            // Upload image to Firebase Storage
            const storageRef = ref(storage, `profileImages/${auth.currentUser.uid}`);
            await uploadBytes(storageRef, file);
            const photoURL = await getDownloadURL(storageRef);

            setSelectedImage(photoURL);
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Failed to upload image');
        }
    };

    const handleSaveChanges = async () => {
        try {
            // Update user profile in Firebase Authentication
            if (selectedImage) {
                await updateProfile(auth.currentUser, { photoURL: selectedImage });
            }

            await updateProfile(auth.currentUser, { displayName: `${firstName} ${lastName}` });

            // Query Firestore for the userProfile document where uid matches auth.currentUser.uid
            const userProfileQuery = query(
                collection(db, 'userProfile'),
                where('uid', '==', auth.currentUser.uid)
            );
            const querySnapshot = await getDocs(userProfileQuery);

            querySnapshot.forEach(async (docSnapshot) => {
                const userDocRef = docSnapshot.ref;
                await setDoc(userDocRef, {
                    photoUrl: selectedImage,
                    altPhoneNumber,
                    gstin,
                    businessName,
                    phoneNumber
                }, { merge: true });
            });

            alert('Profile updated successfully');
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('Failed to update profile');
        }
    };

    return (
        <DashboardLayout proptext='Profile'>
            <>
                <div className="profile--topbar">
                    <div className="profileflex--uploadimage">
                        <div className="imgContainer--profileflexui">
                            <label htmlFor="profileImageUpload">
                                <img
                                    src={selectedImage || auth.currentUser.photoURL || "https://pixio.dexignzone.com/xhtml/images/profile4.jpg"}
                                    alt="Profile"
                                    style={{ cursor: 'pointer' }}
                                />
                            </label>
                            <input
                                id="profileImageUpload"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                        </div>
                        <div className="profileinfo--ui">
                            <h2>{auth.currentUser.displayName}</h2>
                            <p>{auth.currentUser.email}</p>
                        </div>
                    </div>
                </div>
                <div className="profile-input--fieldscontainer">
                    <label>
                        <p>First Name</p>
                        <input
                            type="text"
                            placeholder='Enter Your First Name'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </label>
                    <label>
                        <p>Last Name</p>
                        <input
                            type="text"
                            placeholder='Enter Your Last Name'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </label>
                    <label>
                        <p>Email ID</p>
                        <input
                            type="text"
                            value={auth.currentUser.email}
                            disabled
                        />
                    </label>
                    <label id='verifyID'>
                        <p>Verify Email ID</p>
                        <input
                            type="text"
                            value={auth.currentUser.emailVerified ? "Verified" : "Not Verified"}
                            disabled
                        />
                    </label>
                    <label>
                        <p>Phone Number</p>
                        <input
                            type="text"
                            placeholder='Enter Your Phone Number'
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </label>
                    <label>
                        <p>Alternative Phone Number</p>
                        <input
                            type="text"
                            placeholder='Enter Your Phone Number'
                            value={altPhoneNumber}
                            onChange={(e) => setAltPhoneNumber(e.target.value)}
                        />
                    </label>
                    <label>
                        <p>GSTIN</p>
                        <input
                            type="text"
                            placeholder='Enter Your GSTIN Number'
                            value={gstin}
                            onChange={(e) => setGstin(e.target.value)}
                        />
                    </label>
                    <label>
                        <p>Business Name</p>
                        <input
                            type="text"
                            placeholder='Enter Your Business Name'
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                        />
                    </label>
                </div>
                <div className="saveProfile--btndiv">
                    <button className="discardChanges">
                        Discard Changes
                    </button>
                    <button className="saveProfile" onClick={handleSaveChanges}>
                        Save Changes
                    </button>
                </div>
            </>
        </DashboardLayout>
    );
}

export default Profile;
