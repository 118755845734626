import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../config/firebase.config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import ProductGrid from '../component/product_style/productGrid';
import './route.css';

const ShopByCategory = ({ breadCrumbsText }) => {
    const { categoryId } = useParams();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);

            try {
                // Step 1: Fetch SKUs associated with the category
                const categoriesRef = collection(db, 'categories');
                const q = query(categoriesRef, where('categoryId', '==', categoryId));
                const querySnapshot = await getDocs(q);
                let skus = [];

                querySnapshot.docs.forEach(doc => {
                    const data = doc.data();
                    if (data.products) {
                        skus = skus.concat(data.products.map(product => product.sku));
                    }
                });

                // Step 2: Fetch product details using the SKUs
                const productDetails = [];
                if (skus.length > 0) {
                    for (const sku of skus) {
                        const productsRef = collection(db, 'products');
                        const productQuery = query(productsRef, where('sku', '==', sku));
                        const productSnapshot = await getDocs(productQuery);

                        productSnapshot.docs.forEach(productDoc => {
                            productDetails.push({
                                id: productDoc.id,
                                ...productDoc.data()
                            });
                        });
                    }
                }

                setProducts(productDetails);
                console.log(productDetails);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }

            setLoading(false);
        };

        fetchProducts();
    }, [categoryId]);

    return (
        <div>
            <div className="with20Pad">
                <div className="breadcrumbs">Home {`>`} Shop {`>`} {breadCrumbsText || 'All Products'}</div>
            </div>
            <div className="headingShopping">
                <h1 className='shopHeading'>{breadCrumbsText || 'Shop'}</h1>
                <p className='numProducts'>{products.length} Products</p>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : products.length > 0 ? (
                <ProductGrid productArray={products} />
            ) : (
                <p>No Products Uploaded</p>
            )}
        </div>
    );
}

export default ShopByCategory;
