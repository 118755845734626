import React, { useState } from 'react'
import './footer.css'
import logo from './../../assets/logo-new.jpeg'
import { BsFacebook, BsInstagram, BsTwitterX, BsWhatsapp } from 'react-icons/bs';

const Footer = () => {

    return (
        <>

            <footer className='footer-container'>
                <div className="footer-container-sections">
                    <div className="footer-section-child">
                        <div className="logo-footer">
                            <img src={logo} alt="" />
                        </div>
                        <div className="footer-sections-contact-creds">
                        <ul>
                                <li>Contact : <br></br> +91-9068063000,  +91-9958966630</li>
                                <li>Email : <br /> cursivelettersly@gmail.com</li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-section-child"></div>
                </div>
                <div className="footer-container-sections footer-sections-3">
                    <div className="footer-child--fs">
                        <ul className="footer-child--fs--1" style={{ cursor: 'pointer' }}>
                            <li onClick={() => window.location.href = '/about-us'}>About Us</li>
                            <li onClick={() => window.location.href = '/contact-us'}>Contact Us</li>
                            <li onClick={() => window.location.href = '/shop'}>Shop Page</li>
                            <li onClick={() => window.location.href = '/account'}>Account</li>
                        </ul>
                    </div>
                    <div className="footer-child--fs">
                        <ul className="footer-child--fs--1--social">
                            <li>
                                <BsTwitterX />
                            </li>
                            <li>
                                <BsFacebook />
                            </li>
                            <li>
                                <BsInstagram />
                            </li>
                            <li>
                                <BsWhatsapp />
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>



    )
}

export default Footer
