import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase.config';
import ProductGrid from '../component/product_style/productGrid';
import './route.css';

const Shop = ({ shopText, breadCrumbsText }) => {
    const [productArray, setProductArray] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'products'));
                const productsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setProductArray(productsData);
                console.log(productsData);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };

        fetchProducts();
    }, []);

    return (
        <div>
            <div className="with20Pad">
                <div className="breadcrumbs">
                    Home {'>'} Shop {'>'} {breadCrumbsText || 'All Products'}
                </div>
            </div>
            <div className="headingShopping">
                <h1 className="shopHeading">{shopText || 'Shop'}</h1>
                <p className="numProducts">{productArray.length} Products</p>
            </div>
            <ProductGrid productArray={productArray} />
        </div>
    );
};

export default Shop;
