import React, { useState, useEffect } from 'react';
import { BiMinus } from 'react-icons/bi';
import { PiPlus } from 'react-icons/pi';
import './route.css';
import useWindowSize from '../component/useWindowSize';
import { db, auth } from '../config/firebase.config';
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

const Cart = () => {
    const [productQuantities, setProductQuantities] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const size = useWindowSize();
    const isMobile = size.width < 786;
    const [totalPacks, setTotalPacks] = useState(0);
    const [totalBoxes, setTotalBoxes] = useState(0);
    const [packQuantities, setPackQuantities] = useState({});
    const [boxQuantities, setBoxQuantities] = useState({});

    // Fetch cart items from Firestore
    useEffect(() => {
        const fetchCartItems = async () => {
            if (!auth.currentUser) return;

            try {
                const userProfileRef = doc(db, 'userProfile', auth.currentUser.uid);
                const userProfileDoc = await getDoc(userProfileRef);
                if (userProfileDoc.exists()) {
                    const userProfileData = userProfileDoc.data();
                    const items = userProfileData.cart || [];
                    setCartItems(items);
                    console.log(items);

                    // Initialize product quantities
                    const initialQuantities = {};
                    items.forEach(item => {
                        initialQuantities[item.productId] = item.count || 1; // Default quantity to item's quantity
                    });
                    setProductQuantities(initialQuantities);
                    console.log(initialQuantities);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error('Error fetching cart items:', error);
            }
        };

        fetchCartItems();
    }, [auth.currentUser]);

    const updateFirestoreItem = async (productId, newQuantity) => {
        if (!auth.currentUser) return;


        try {
            const userProfileRef = doc(db, 'userProfile', auth.currentUser.uid);
            const userProfileDoc = await getDoc(userProfileRef);
            if (userProfileDoc.exists()) {
                const userProfileData = userProfileDoc.data();
                const cart = userProfileData.cart || [];
                const updatedCart = cart.map(item =>
                    item.productId === productId
                        ? { ...item, count: newQuantity }
                        : item
                );
                await updateDoc(userProfileRef, { cart: updatedCart });
                console.log(`Updated Firestore with productId: ${productId}, newQuantity: ${newQuantity}`);
            }
        } catch (error) {
            console.error('Error updating item in Firestore:', error);
        }
    };

    // Handle increment of quantity
    const handleIncrement = (productId) => {
        setProductQuantities(prevQuantities => {
            const currentQuantity = prevQuantities[productId] || 1;
            const item = cartItems.find(cartItem => cartItem.productId === productId);

            if (!item) {
                console.log(`Item with productId ${productId} not found in cart.`);
                return prevQuantities;
            }

            const maxQuantity = item.purchaseType === 'packs'
                ? item.quantity / item.packQuantity
                : item.purchaseType === 'boxes'
                    ? item.quantity / item.packQuantity / item.boxQty
                    : Infinity; // Default to unlimited if not 'packs' or 'boxes'

            // Check if incrementing would exceed the allowed quantity
            if (currentQuantity >= maxQuantity) {
                console.log(`Cannot increment ${productId}. Maximum allowed quantity reached.`);
                return prevQuantities;
            }

            const newQuantities = {
                ...prevQuantities,
                [productId]: currentQuantity + 1
            };

            console.log(`Incremented quantity for ${productId}: ${newQuantities[productId]}`);
            updateTotals(newQuantities);
            updateFirestoreItem(productId, newQuantities[productId]);

            return newQuantities;
        });
    };


    // Handle decrement of quantity
    const handleDecrement = (productId) => {
        setProductQuantities(prevQuantities => {
            const newQuantities = {
                ...prevQuantities,
                [productId]: Math.max((prevQuantities[productId] || 1) - 1, 1)
            };
            console.log(`Decremented quantity for ${productId}: ${newQuantities[productId]}`);
            updateTotals(newQuantities);
            updateFirestoreItem(productId, newQuantities[productId]);
            return newQuantities;
        });
    };

    // Handle removal of item from cart
    const handleRemove = async (productId) => {
        try {
            // Step 1: Remove the item locally from the cart state
            setCartItems(prevItems => prevItems.filter(item => item.productId !== productId));

            // Step 2: Update product quantities locally
            setProductQuantities(prevQuantities => {
                const { [productId]: _, ...rest } = prevQuantities; // Remove the quantity for the removed item
                updateTotals(rest);
                return rest;
            });

            // Step 3: Update Firestore to remove the item from the user's cart
            const q = query(collection(db, "userProfile"), where("uid", "==", auth.currentUser.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const profileDoc = querySnapshot.docs[0];
                const profileData = profileDoc.data();

                // Filter out the removed item from the cart
                const updatedCart = profileData.cart.filter(item => item.productId !== productId);

                // Update Firestore with the new cart
                await updateDoc(profileDoc.ref, { cart: updatedCart });

                console.log(`Item with productId ${productId} removed from Firestore.`);
                toast.success("Item removed from cart successfully!");
            } else {
                console.log("No profile found for the user!");
                toast.error("Profile not found. Please try again.");
            }
        } catch (error) {
            console.error("Error removing item from cart: ", error);
            toast.error("Failed to remove item from cart. Please try again.");
        }
    };


    const updateTotals = (quantities) => {
        let packs = 0;
        let boxes = 0;
        let newPackQuantities = {};
        let newBoxQuantities = {};

        cartItems.forEach(item => {
            const count = quantities[item.productId] || 1;
            if (item.purchaseType === 'packs') {
                packs += count * item.packQuantity;
                newPackQuantities[item.productId] = count * item.packQuantity;
            } else if (item.purchaseType === 'boxes') {
                boxes += count * item.boxQty * item.packQuantity;
                newBoxQuantities[item.productId] = count * item.boxQty * item.packQuantity;
            }
        });

        setTotalPacks(packs);
        setTotalBoxes(boxes);
        setPackQuantities(newPackQuantities);
        setBoxQuantities(newBoxQuantities);
    };

    // Update totals on component mount or when cart items or product quantities change
    useEffect(() => {
        updateTotals(productQuantities);
    }, [cartItems, productQuantities]);

    const handleBookNow = async () => {
        if (!auth.currentUser) {
            toast.error("You need to be logged in to place an order.");
            return;
        }

        let phoneNumber = '';

        try {
            // Query the profiles collection to find the document where uid matches currentUser.uid
            const q = query(collection(db, "userProfile"), where("uid", "==", auth.currentUser.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming there's only one document per user
                const profileDoc = querySnapshot.docs[0];
                const profileData = profileDoc.data();
                phoneNumber = profileData.phoneNumber || '';
            }
        } catch (error) {
            console.error("Error fetching user profile: ", error);
            toast.error("Failed to fetch user profile. Please try again.");
            return;
        }
        if (phoneNumber !== '') {

            try {
                // Prepare order data
                const orderData = cartItems.map(item => ({
                    customerName: auth.currentUser.displayName,
                    uid: auth.currentUser.uid,
                    email: auth.currentUser.email,
                    productId: item.productId,
                    orderQuantity: item.cartQuantity,
                    orderPrice: (item.price * item.cartQuantity).toFixed(2),
                    orderDate: new Date().toLocaleDateString('en-GB'), // Format as dd/mm/yyyy
                    orderTime: new Date().toLocaleTimeString(),
                    status: 'In Review',
                    orderIdUnique: v4(),
                    ...item // Spread item details
                }));

                // Push orders to Firestore without batch processing
                await Promise.all(orderData.map(async order => {
                    const orderRef = doc(collection(db, 'orders')); // Create a new document for each order
                    await setDoc(orderRef, order);
                }));

                // Optionally, clear the cart after a successful order
                const userProfileRef = doc(db, 'userProfile', auth.currentUser.uid);
                await updateDoc(userProfileRef, { cart: [] });
                alert('Order Place Successfully')
                toast.success("Order placed successfully!");
                setCartItems([]);
                setProductQuantities({});
            } catch (error) {
                console.error('Error placing order:', error);
                toast.error("Failed to place the order. Please try again.");
            }
        } else {
            alert('No Phone Number Added. Kindly Update Profile to Order')

        }

    };



    return (
        <div className='cart--container'>
            <div className="cart--products">
                {cartItems.length > 0 ? (
                    cartItems.map((cart, index) => {
                        const quantity = productQuantities[cart.productId] || 1;
                        const totalQuantity = cart.purchaseType == 'boxes'
                            ? quantity * cart.boxQty * cart.packQuantity
                            : quantity * cart.packQuantity;

                        const truncatedDescription = cart.productDescription.length > 150
                            ? cart.productDescription.slice(0, 150) + '...'
                            : cart.productDescription;

                        return (
                            <div className='cart--singledetails' key={index}>
                                <div className="cart--product-image">
                                    <img src={cart.productImg} alt="" />
                                </div>
                                <div className="pcart-product--details">
                                    <div className="cart--producttitle">{cart.productName}</div>
                                    <div className="cart--productdescription">{truncatedDescription}</div>
                                    <div className="quantityinfo" style={{ padding: '12px 0px 0px 0px' }}>
                                        <div className="quantitybtn">
                                            {
                                                isMobile ? (
                                                    <>Quantity : {totalQuantity}</>
                                                ) : (
                                                    <>Individual Quantity : {cart.cartQuantity}</>
                                                )
                                            }
                                        </div>
                                        <div className="quantitybtn">
                                            {
                                                isMobile ? (
                                                    <>{cart.purchaseType === 'packs' ? 'Packs' : 'Boxes'} : {quantity}</>
                                                ) : (
                                                    <>Quantity of Packs : {cart.cartQuantity / cart.packQuantity}</>
                                                )
                                            }
                                        </div>
                                        <div className="quantitybtn">
                                            {
                                                isMobile ? (<>{(cart.price * cart.cartQuantity).toFixed(2)}</>) : (
                                                    <>Price : {(cart.price * cart.cartQuantity).toFixed(2)}</>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="noofpackes">
                                        {/* Change Quantity */}
                                        <div className="product-functions--componentfs">
                                            {/* <div className="product--counter-quantity">
                                                <button className="counter--buttons" onClick={() => handleDecrement(cart.productId)}>
                                                    <BiMinus />
                                                </button>
                                                <p className="counter--state-count">{quantity}</p>
                                                <button className="counter--buttons" onClick={() => handleIncrement(cart.productId)}>
                                                    <PiPlus />
                                                </button>
                                            </div> */}
                                            <button className="remove--button" onClick={() => handleRemove(cart.productId)}>Remove</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="empty-cart-message">ADD MORE ITEMS TO ENJOY</div>
                )}
            </div>
            <div className="cart--summary">
                <div className="cartsummary-details">
                    <h2>Cart Summary</h2>
                    <div className="hrContent">
                        <hr />
                    </div>
                    <div className="cartsummary--details-child">
                        <div className="cartsummaryChild">
                            {/* Total Quantities: Sum of all `item.orderQuantity` */}
                            Total Quantities: {cartItems.reduce((total, item) => total + item?.cartQuantity, 0)}
                        </div>
                        <div className="cartsummaryChild">
                            {/* Total Price: Sum of all `item.orderPrice` */}
                            Total Price: ₹{cartItems.reduce((total, item) => total + parseFloat(item?.cartPrice), 0).toFixed(2)}
                        </div>
                        <div className="cartsummaryChild">
                            {/* Total Types: Number of different items */}
                            Total Types: {cartItems.length}
                        </div>
                    </div>
                    <div className="btnGrp-flex">
                        <button className="bookNow" onClick={handleBookNow}>
                            Book Now
                        </button>
                        <button className="contactus--btn" onClick={() => window.location.href = '/contact-us'}>
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Cart;
