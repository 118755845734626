import React, { useState } from 'react';
import './component.css';
import { FaArrowCircleRight } from 'react-icons/fa';
import { getAuth, signOut } from 'firebase/auth';

const DashboardLinks = ({ stateActive }) => {
    const [icon, setIcon] = useState('https://pixio.dexignzone.com/xhtml/images/profile4.jpg');
    const auth = getAuth();

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Redirect to the login page or homepage after successful logout
            window.location.href = '/login';
        }).catch((error) => {
            // Handle any errors
            console.error('Logout error:', error);
        });
    };

    const menu = [
        {
            name: 'Profile',
            link: '/account'
        },
        // {
        //     name: 'Address',
        //     link: '/account/address'
        // },
        {
            name: 'Orders',
            link: '/account/orders'
        },
        {
            name: 'Cart',
            link: '/cart'
        },
        // {
        //     name: 'Raise Ticket',
        //     link: '/'
        // }
    ];

    return (
        <div className="dashboardlink--container">
            <div className="profileDisplayInfo">
                <div className="profilepicture--container">
                    <img src={auth.currentUser.photoURL} alt="Profile" />
                </div>
                <p className="profileName">
                    {auth.currentUser.displayName}
                </p>
                <p className="emailProfile">
                    {auth.currentUser.email}
                </p>
            </div>
            <div className="dashboardlinks--container-child">
                {menu.map((item, index) => (
                    <div key={index} onClick={() => window.location.href = item.link} className={`dashboardlinks--container-child--li ${stateActive === item.name ? 'active' : ''}`}>
                        {item.name}
                        <FaArrowCircleRight />
                    </div>
                ))}
                <div onClick={handleLogout} className="dashboardlinks--container-child--li">
                    Logout
                </div>
            </div>
        </div>
    );
};

export default DashboardLinks;
