import React, { useState, useEffect } from 'react';
import './slider.css';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import useWindowSize from './useWindowSize';
import slider1 from './../MOB HEADER 1.gif'
import slider2 from './../MOB HEADER 2.jpg'
import slider3 from './../WEB HEADER 1.gif'
import slider4 from './../WEB HEADER 2.jpg'

const SliderJsx = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const size = useWindowSize();

    const sliderArr = [
        {
            img: slider3,
            mobileImg: slider1
        },
        {
            img: slider4,
            mobileImg: slider2
        },

    ];

    useEffect(() => {
        const autoSlide = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => {
            clearInterval(autoSlide);
        };
    }, []);

    const nextSlide = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderArr.length);
    };

    const prevSlide = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => (prevIndex - 1 + sliderArr.length) % sliderArr.length);
    };

    const handleTransitionEnd = () => {
        setIsTransitioning(false);
    };

    const isMobile = size.width <= 768; // Adjust the width as needed for mobile detection

    return (
        <div className="slider-container">
            <div className="slider-content" onTransitionEnd={handleTransitionEnd} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {sliderArr?.map((slide, index) => (
                    <img key={index} src={isMobile ? slide.mobileImg : slide.img} alt={`slider-${index}`} />
                ))}
            </div>
            <button className="arrow-button-slider prev" onClick={prevSlide}>
                <IoIosArrowBack />
            </button>
            <button className="arrow-button-slider next" onClick={nextSlide}>
                <IoIosArrowForward />
            </button>
        </div>
    );
};

export default SliderJsx;
