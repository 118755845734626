import React, { useState } from 'react';
import './../component.css';
import { BiCaretLeft, BiCaretRight, BiMinus } from 'react-icons/bi';
import { PiPlus } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

const ProductGrid = ({ productArray }) => {
    const navigate = useNavigate()

    const [counts, setCounts] = useState(productArray?.map(() => 1));
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 16;

    const handleIncrement = (index) => {
        setCounts((prevCounts) =>
            prevCounts?.map((count, i) => i === index && count < productArray[i].quantity ? count + 1 : count)
        );
    };

    const handleDecrement = (index) => {
        setCounts((prevCounts) =>
            prevCounts?.map((count, i) => i === index && count > 1 ? count - 1 : count)
        );
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = productArray.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(productArray.length / productsPerPage);

    return (
        <div>
            <div className="product-grid--productstyle">
                {currentProducts?.map((product, index) => (
                    <div
                        key={index}
                        className="product-container--wrapper-mobile"
                        onClick={() => navigate(`/product/${product.barcode}`)}
                    >
                        <section className="product--wrapper-grid-1-mobile">
                            <img src={product.featuredImageUrl} alt={product.productName} />
                        </section>
                        <section className="product--wrapper-grid-2">
                            <div className="product-title--componentfs">{product.productName}</div>
                            <div className="product-functions--componentfs">

                                <button className="booknow--fast-component">Book Now</button>
                            </div>
                        </section>
                    </div>
                ))}
            </div>
            <div className="pagination">
                <button className='butnPage'
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                >
                    <BiCaretLeft />
                </button>
                <button className='pageDet'> {currentPage} / {totalPages}</button>
                <button className='butnPage'
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                >
                    <BiCaretRight />
                </button>
            </div>
        </div>
    );
};

export default ProductGrid;
