import React, { useState } from 'react';
import './table.css';

const TableJsx = ({ orders }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    // Function to parse the date in dd/mm/yyyy format
    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };

    // Sort orders by date in ascending order
    const sortedOrders = [...orders].sort((a, b) => parseDate(b.orderDate) - parseDate(a.orderDate));

    // Calculate the indices for the current page slice
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = sortedOrders.slice(indexOfFirstRow, indexOfLastRow);

    // Calculate the total number of pages
    const totalPages = Math.ceil(sortedOrders.length / rowsPerPage);

    // Calculate the range of pages to be shown
    const maxPageNumbersToShow = 3;
    const halfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);

    let startPage = Math.max(currentPage - halfMaxPageNumbersToShow, 1);
    let endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

    if (endPage - startPage < maxPageNumbersToShow - 1) {
        startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    // Function to handle page change
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    // Function to handle previous page click
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Function to handle next page click
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div>
            <main className="table" id="customers_table">
                <section className="table__header">
                    <h1>Customer's Orders</h1>
                </section>
                <section className="table__body">
                    <table>
                        <thead>
                            <tr>
                                <th> Id </th>
                                <th> Product </th>
                                <th> Quantity </th>
                                <th> Price </th>
                                <th> Order Date </th>
                                <th> Status </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRows.map((order) => (
                                <tr key={order.id}>
                                    <td>{order?.id ? (order?.id.length > 7 ? `${order?.id.slice(0, 7)}...` : order?.id) : '-'}</td>
                                    <td><img src={order.featuredImageUrl} alt={order.productName} /> {order?.productName ? (order?.productName.length > 10 ? `${order?.productName.slice(0, 10)}...` : order?.productName) : '-'}</td>
                                    <td>{order.orderQuantity}</td>
                                    <td>{order.orderPrice}</td>
                                    <td>{order.orderDate}</td>
                                    <td><strong>{order.status}</strong></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
                <div className="pagination paginationOrders">
                    <button onClick={handlePreviousPage} id='BtnPagination' disabled={currentPage === 1}>{"<"}</button>
                    {pageNumbers.map((number) => (
                        <button
                            key={number}
                            onClick={() => handlePageChange(number)}
                            className={`buttonPAginationCircle ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </button>
                    ))}
                    <button onClick={handleNextPage} id='BtnPagination' disabled={currentPage === totalPages}>{">"}</button>
                </div>
            </main>
        </div>
    );
};

export default TableJsx;
