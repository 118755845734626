import React, { Children } from 'react'
import DashboardLinks from '../../component/dashboardLinks'
import { Outlet } from 'react-router-dom'

const DashboardLayout = ({ proptext, children }) => {
    return (
        <div>
            <div className="dashboardBreadCrumbs">
                <h1>My Account</h1>
                <p>Home  {'>'} My Account</p>
            </div>
            <div className="dashboard--content">
                <DashboardLinks stateActive={proptext} />
                <div className="dashboard--content--right">
                    <div className="fullWidth--dashboard">
                        {
                            children
                        }           </div>
                </div>
            </div>

        </div>
    )
}

export default DashboardLayout
