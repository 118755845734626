import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './route.css';
import { BiMinus } from 'react-icons/bi';
import { PiPlus } from 'react-icons/pi';
import { addDoc, collection, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../config/firebase.config';
import logo from './../assets/LOGO CURSIVE rectangular-02.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 } from 'uuid';

const ProductSingle = () => {
    const navigate = useNavigate()
    const { productId } = useParams();
    const [products, setProducts] = useState(null);
    const [count, setCount] = useState(null);
    const [purchaseType, setPurchaseType] = useState('packs');
    const [activeTab, setActiveTab] = useState('description');
    const [featuredImage, setFeaturedImage] = useState(logo);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const productsRef = collection(db, "products");
                const q = query(productsRef, where("barcode", "==", productId));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const productData = querySnapshot.docs[0].data();


                    // Validate the quantities
                    const { quantity, packQuantity, boxQty } = productData;
                    let modifiedProductData = { ...productData };

                    // if (quantity == 0) {
                    //     modifiedProductData.boxQty = 0;
                    //     modifiedProductData.packQuantity = 0;
                    //     setCount(0)
                    // }
                    // if (quantity && packQuantity && boxQty
                    // ) {
                    //     const dividedByPack = quantity / packQuantity / boxQty;
                    //     if (dividedByPack < 1) {
                    //         modifiedProductData.boxQty = 0;
                    //         console.warn("Quantity divided by packQuantity and boxQty is less than 1, setting boxQty to 0.");
                    //     }
                    //     // }
                    // }

                    setProducts(modifiedProductData);
                    setFeaturedImage(modifiedProductData.featuredImageUrl || logo);
                    setCount(Number(modifiedProductData.minBuying))
                    console.log(modifiedProductData);
                } else {
                    console.log("No such document!");
                }

                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error("Error fetching product: ", error);
                setLoading(false); // Ensure loading is false even if there's an error
            }
        };

        fetchProduct();
    }, [productId]);

    const handleIncrement = () => {
        // console.log('ount');
        const newCount = Number(count) + Number(products?.minBuying);
        console.log(Number(products.minBuying));
        console.log(count);
        console.log(newCount);
        console.log(Number(products?.quantity) > count);

        if (Number(products?.quantity) > newCount) {
            // if ((products.minBuying) <= newCount) {
            setCount(Number(newCount));
            // }
        } else {
        }

    }

    const handleIncrementOld = () => {
        // if (type == 'packs' && products?.packQuantity == 0) {
        //     console.log(type);

        //     return;
        // }
        // if (type == 'boxes' && products?.boxQty == 0) {
        //     console.log(type);
        //     return;
        // }

        // const newCount = Number(count) + 1; // Parse count to a number and increment
        // const currentQuantity = Number(calculateIndividualQuantity()); // Ensure it's a number
        // const maxQuantity = Number(products?.quantity); // Ensure it's a number
        // const packQuantity = Number(products?.packQuantity); // Ensure it's a number

        // // Check if the new quantity is within the allowed range
        // if (type == 'packs') {
        //     if (currentQuantity < maxQuantity && currentQuantity + packQuantity < maxQuantity) {
        //         setCount(newCount);
        //     } else if (currentQuantity + packQuantity === maxQuantity) {
        //         setCount(newCount);
        //     } else {
        //         return null
        //     }
        // } else {
        //     if (currentQuantity < maxQuantity && currentQuantity + (packQuantity * products.boxQty) < maxQuantity) {
        //         setCount(newCount);
        //     } else if (currentQuantity + packQuantity === maxQuantity) {
        //         setCount(newCount);
        //     } else {
        //         return null
        //     }
        // }

        console.log(count);
        const newCount = Number(count) + Number(products.packQuantity);
        console.log(newCount);

        // if (products?.quantity >= count) {
        // if ((products.minBuying * products.packQuantity) <= count) {
        setCount(newCount);
        // }
        // }

        // setCount(newCount)


    };

    // const handlePurchaseTypeChange = (type) => {
    //     setPurchaseType(type);
    //     if (products.boxQty == 0 && type == 'boxes') {
    //         setCount(0)
    //         return null
    //     } else if (products.packQuantity === 0 && type == 'packs') {
    //         setCount(0)
    //         return null
    //     } else {
    //         setCount(1)
    //     }

    // };

    const calculateIndividualQuantity = () => {
        if (purchaseType === 'packs') {
            return count * products?.packQuantity;
        } else if (purchaseType === 'boxes') {
            return count * products?.boxQty * products?.packQuantity;
        }
        return count;
    };



    const handleDecrement = () => {
        // Calculate the new count after decrement
        const newCount = count - Number(products.minBuying);

        // Ensure the new count is not less than minBuying
        if (newCount >= Number(products.minBuying)) {
            setCount(newCount);
        }
    };


    const handleGalleryClick = (image) => {
        setFeaturedImage(image);
    };


    const formatDateTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return {
            orderDate: `${day}/${month}/${year}`,
            orderTime: `${hours}:${minutes}:${seconds}`
        };
    };

    const handleBookNow = async () => {
        if (!auth.currentUser) {
            toast.error("You need to be logged in to add items to the cart.");
            navigate('/account');
            return;
        }

        if (!auth.currentUser.emailVerified) {
            toast.error("Please verify your email address to add items to the cart.");
            return;
        }

        const confirmation = window.confirm("Are you sure you want to book this product?");
        if (!confirmation) return;

        let phoneNumber = '';

        try {
            // Query the profiles collection to find the document where uid matches currentUser.uid
            const q = query(collection(db, "userProfile"), where("uid", "==", auth.currentUser.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming there's only one document per user
                const profileDoc = querySnapshot.docs[0];
                const profileData = profileDoc.data();
                phoneNumber = profileData.phoneNumber || '';
            }
        } catch (error) {
            console.error("Error fetching user profile: ", error);
            toast.error("Failed to fetch user profile. Please try again.");
            return;
        }

        if (phoneNumber !== '') {
            // Proceed with booking if phoneNumber is not an empty string
            const { orderDate, orderTime } = formatDateTime(new Date());

            const orderData = {
                customerName: auth.currentUser.displayName,
                uid: auth.currentUser.uid,
                productId: productId,
                orderIdUnique: v4(),
                email: auth.currentUser.email,
                orderQuantity: count,
                orderPrice: (count * Number(products.price)).toFixed(2),
                orderDate: orderDate, // store date in dd/mm/yyyy 
                orderTime: orderTime,
                status: 'In Review',
                ...products
            };

            try {
                await addDoc(collection(db, "orders"), orderData);
                toast.success("Order placed successfully!");
            } catch (error) {
                console.error("Error placing order: ", error);
                toast.error("Failed to place order. Please try again.");
            }
        } else {
            // If phoneNumber is an empty string, call addToCart function
            alert('No Phone Number Added. Kindly Update Profile to Order')
            handleAddToCart(); // Ensure addToCart function is defined elsewhere
        }
    };




    const handleAddToCart = async () => {
        if (!auth.currentUser) {
            toast.error("You need to be logged in to add items to the cart.");
            navigate('/account')
            return;
        }
        console.log(auth.currentUser.uid);

        try {
            // Query the profiles collection to find the document where uid matches currentUser.uid
            const q = query(collection(db, "userProfile"), where("uid", "==", auth.currentUser.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming there's only one document per user
                const profileDoc = querySnapshot.docs[0];
                const profileData = profileDoc.data();

                // Check if the item is already in the cart
                const existingItemIndex = profileData.cart.findIndex(item => item.productId === productId);

                let updatedCart;
                if (existingItemIndex !== -1) {
                    // Item exists, update the quantity and other details
                    updatedCart = profileData.cart.map((item, index) =>
                        index === existingItemIndex
                            ? {
                                ...item,
                                cartQuantity: count,
                                cartPrice: (count * Number(products.price)).toFixed(2),
                                // purchaseType: purchaseType,
                                // count: count,
                                ...products
                            }
                            : item
                    );
                } else {
                    // Item doesn't exist, add it to the cart
                    updatedCart = [
                        ...profileData.cart,
                        {
                            productId: productId,
                            cartQuantity: count,
                            cartPrice: (count * Number(products.price)).toFixed(2),
                            // purchaseType: purchaseType,
                            // count: count,
                            ...products
                        }
                    ];
                }

                await updateDoc(profileDoc.ref, { cart: updatedCart });
                toast.success("Product added to cart successfully!");
            } else {
                console.log("No profile found for the user!");
                toast.error("Profile not found. Please try again.");
            }
        } catch (error) {
            console.error("Error adding to cart: ", error);
            toast.error("Failed to add to cart. Please try again.");
        }
    };


    if (loading) {
        return <div>Loading...</div>; // Show a loading indicator while loading
    }

    return (
        <div className='productSingle'>
            <div className="productImages--single">
                <div className="productimage--featured">
                    <img src={featuredImage} alt={products?.productName} />
                </div>
                <div className="productgallery--images">
                    {products?.galleryImages950Url.map((img, idx) => (
                        <img
                            src={img}
                            alt={`${products?.productName} ${idx}`}
                            key={idx}
                            onClick={() => handleGalleryClick(img)}
                            className={img === featuredImage ? 'active' : ''}
                        />
                    ))}
                </div>
            </div>
            <div className="productData--single" id='productSecondRightPage'>
                <h2>{products?.productName}</h2>
                <hr />
                <div className="allFuncData">
                    <div className="totalIndividualQuantity">
                        <b>Unit Rate:
                        </b>  <span>{products.price} INR</span> ||
                        <b>
                            &nbsp; Pack Quantity :
                        </b>
                        <span>{Number(products?.packQuantity)}</span> ||
                        <b>
                            &nbsp;One Pack Price :
                        </b>
                        <span>{(Number(products.price) * Number(products?.packQuantity)).toFixed(2)} INR</span>
                        {/* Pack Quantity: <span>{count * products.packQuantity}</span> || Individual Quantity : <span>{count}</span> */}
                    </div>
                    <div className="totalIndividualQuantity">
                        {/* Unit Price: <span>{products.price}</span> || Pack Price : <span>{products.price * products.packQuantity}</span> */}
                    </div>
                    <div className="totalIndividualQuantity">
                        <b>
                            Minimum Buying Quantity :
                        </b>
                        <span>{Number(products?.minBuying)}</span>                     </div>
                    {/* <div className="selectTypeofPurchase">
                        Select Type of Purchase:
                        <button
                            className={`typeofpurchase--child ${purchaseType === 'packs' ? 'active' : ''}`}
                            onClick={() => handlePurchaseTypeChange('packs')}
                        >
                            Packs
                        </button>
                        <button
                            className={`typeofpurchase--child ${purchaseType === 'boxes' ? 'active' : ''}`}
                            onClick={() => handlePurchaseTypeChange('boxes')}
                        >
                            Boxes
                        </button>
                    </div> */}
                    <div className="noofpackes">
                        <b>

                            Select Your Quantity :
                        </b>
                        <div className="product-functions--componentfs">
                            <div className="product--counter-quantity">
                                <button className="counter--buttons" onClick={handleDecrement}>
                                    <BiMinus />
                                </button>
                                <p className="counter--state-count">{count}</p>
                                <button
                                    className="counter--buttons"
                                    onClick={handleIncrement}
                                >
                                    <PiPlus />
                                </button>

                            </div>
                            <button className="booknow--fast-component" onClick={handleBookNow}>Book Now</button>
                        </div>
                    </div>
                    <div className="totalIndividualQuantity">
                        <b>
                            Total Price : &nbsp;
                        </b>
                        <span>{(count * products.price).toFixed(2)} INR</span>                    </div>
                </div>
                <div className="tabs">
                    <div className="tabnames">
                        <div
                            className={`tabnames-child ${activeTab === 'description' ? 'active' : ''}`}
                            onClick={() => setActiveTab('description')}
                        >
                            Description
                        </div>
                        <div
                            className={`tabnames-child ${activeTab === 'info' ? 'active' : ''}`}
                            onClick={() => setActiveTab('info')}
                        >
                            Product Info
                        </div>
                    </div>
                    <div className="tabcontent">
                        {activeTab === 'description' && (
                            <div className="tabcontent-child">
                                <p className='tabcontentp'>
                                    {products?.productDescription}
                                </p>
                            </div>
                        )}
                        {activeTab === 'info' && (
                            <div className="tabcontent-child">
                                <div className="skuInfo">
                                    SKU : <button>{products?.sku}</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="addtocartorbuy fixed-bottom">
                    <button className="addtocart" onClick={handleAddToCart}>Add to Cart</button>
                    <button className="booknow" onClick={handleBookNow}>Book Now</button>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ProductSingle;
