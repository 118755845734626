import React, { useState, useRef } from 'react';
import './component.css';
import useWindowSize from './useWindowSize';
import HeadingWithSpace from './headingWithSpace';
import { useNavigate } from 'react-router-dom';

export const CategoryHome = ({ categoryArray }) => {
    const navigate = useNavigate()
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const size = useWindowSize()
    const containerRef = useRef(null);
    const isMobile = size.width < 786
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - containerRef.current.offsetLeft;
        const scroll = x - startX;
        containerRef.current.scrollLeft = scrollLeft - scroll;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleTouchStart = (e) => {
        setIsDragging(true);
        setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const x = e.touches[0].pageX - containerRef.current.offsetLeft;
        const scroll = x - startX;
        containerRef.current.scrollLeft = scrollLeft - scroll;
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
    };



    return (
        <>
            {
                isMobile ?
                    (
                        <div className='category-home'>
                            <div className='category-home---p' style={{ width: '100%' }}>


                                <HeadingWithSpace buttonBool={true} headingTitle="Categories for You" subHeading="Discover our curator pick for you" link="/category" />
                            </div>
                            <div
                                className="categories-scroll-view"
                                ref={containerRef}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp} // Handle mouse leaving the area
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                            >
                                {categoryArray?.map((category, index) => (
                                    <div
                                        className="category-div-fs01"
                                        key={index}
                                        onClick={() => navigate(`/shop/${category.categoryId}`)}
                                    >
                                        <div className="category-img--div--fs01" style={{ width: '100%' }}>
                                            <img src={category.categoryImg} alt={category.categoryName} />
                                        </div>
                                        <div className="category-item">
                                            {category.categoryName}
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    ) : (
                        <div className='category-home'>
                            <div className='category-home--p'>
                                <p className="category-home--p-01">Categories for You</p>
                                <p className="category-home--p-02">Discover our curator pick for you</p>
                            </div>
                            <div
                                className="categories-scroll-view"
                                ref={containerRef}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp} // Handle mouse leaving the area
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                            >
                                {categoryArray?.map((category, index) => (
                                    <div className="category-div-fs01" key={index}
                                        onClick={() => navigate(`/shop/${category.categoryId}`)}
                                    >
                                        <div className="category-img--div--fs01">
                                            <img src={category.categoryImg} alt={category.categoryName} />
                                        </div>
                                        <div className="category-item">{category.categoryName}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
            }


        </>
    );
};
