import React from 'react';
import './../route.css';
import DashboardLinks from '../../component/dashboardLinks';
import DashboardLayout from './dashboardLayout';

const addresses = [
    {
        name: 'John Doe',
        line1: '56 Subhas Nagar',
        city: 'Kolkata',
        state: 'City',
        mobile: '+91 7439398783',
        email: 'johndoe@gmail.com'
    },
    {
        name: 'Jane Smith',
        line1: '123 Park Avenue',
        city: 'New York',
        state: 'NY',
        mobile: '+1 212-555-0198',
        email: 'janesmith@example.com'
    }
    // Add more address objects as needed
];

const Address = () => {
    return (
        <DashboardLayout proptext='Address'>
            <div className='addressProofile--container'>
                <p className='shippingandbillingadderss-infotext'>
                    The Following will be used on the checkout page & billing by default
                </p>
                <div className="addresses">
                    {addresses.map((address, index) => (
                        <div className="address-child-container" key={index}>
                            <p className="addressChildName">{address.name}</p>
                            <p className="address-Line1">{address.line1}</p>
                            <p className="state--city--address">{`${address.city}, ${address.state}`}</p>
                            <p className="mobileNumber--address">{address.mobile}</p>
                            <p className="email--address">{address.email}</p>
                        </div>
                    ))}
                </div>
            </div>
        </DashboardLayout>
    );
};

export default Address;
