import React, { useState, useEffect } from 'react';
import DashboardLayout from './dashboardLayout';
import TableJsx from '../../component/table';
import { db, auth } from '../../config/firebase.config';
import { collection, query, where, getDocs } from 'firebase/firestore';

const OrderJsx = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const user = auth.currentUser;
                if (user) {
                    const q = query(collection(db, 'orders'), where('uid', '==', user.uid));
                    const querySnapshot = await getDocs(q);
                    const ordersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setOrders(ordersList);
                } else {
                    console.error('No user is authenticated');
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, []);

    return (
        <div>
            <DashboardLayout proptext='Orders'>
                <TableJsx orders={orders} />
            </DashboardLayout>
        </div>
    );
};

export default OrderJsx;
