import React from 'react';
import './route.css';

const AboutUs = () => {
    return (
        <div className='aboutus-container'>
            <h1>About Cursive_Letter_ LY</h1>
            <section className='company-overview'>
                <h2>Company Overview</h2>
                <p>
                    Cursive_Letter_ LY is a leading importer, stockist, retailer, and distributor of premium and innovative stationery items and gift products. Dedicated to enhancing creativity and productivity through high-quality products, our company has been delivering unique and stylish stationery since its establishment in 2022. We cater to the diverse needs of kids, students, professionals, and stationery enthusiasts across Haryana, Punjab, and Rajasthan regions, serving various retailers and distributors.
                </p>
            </section>
            <section className='mission-statement'>
                <h2>Mission Statement</h2>
                <p>
                    Our mission is to inspire creativity and bring joy to everyday tasks by providing access to the finest stationery products from around the world. We are committed to offering a curated selection of high-quality, aesthetically pleasing, and functional stationery items that meet the evolving demands of our customers.
                </p>
            </section>
            <section className='product-range'>
                <h2>Product Range</h2>
                <ul>
                    <li><strong>Notebooks and Diaries:</strong> Beautifully designed notebooks and diaries in various sizes, formats, and cover materials to suit different preferences.</li>
                    <li><strong>Writing Instruments:</strong> A diverse collection of pens, pencils, markers, and highlighters that provide a smooth and enjoyable writing experience.</li>
                    <li><strong>Desk Accessories:</strong> Stylish and practical desk organizers, paperweights, and other accessories that enhance productivity and workspace aesthetics.</li>
                    <li><strong>Art Supplies:</strong> High-quality art materials, including sketchbooks, coloring pencils, markers, and brushes for artists and hobbyists.</li>
                    <li><strong>Gift Items:</strong> Thoughtfully curated stationery gift sets and personalized items perfect for special occasions.</li>
                </ul>
            </section>
            <section className='global-sourcing'>
                <h2>Global Sourcing</h2>
                <p>
                    We have established strong partnerships with renowned manufacturers and suppliers from around the globe, allowing us to bring the latest trends and innovations in stationery to our customers. Our team is constantly on the lookout for new and exciting products that align with our commitment to quality and design.
                </p>
            </section>
            <section className='distribution-network'>
                <h2>Distribution Network</h2>
                <p>
                    Cursive_Letter_ LY has a well-established distribution network that ensures timely and efficient delivery of our products to retailers, wholesalers, and online platforms. We work closely with our partners to ensure that our products are accessible to customers across the region.
                </p>
            </section>
            <section className='customer-focus'>
                <h2>Customer Focus</h2>
                <p>
                    Customer satisfaction is at the heart of everything we do. We strive to provide exceptional service and support to our clients, offering tailored solutions to meet their specific needs. Whether you are a retailer looking to stock unique stationery items or an individual searching for the perfect gift, Cursive_Letter_ LY is here to help.
                </p>
            </section>
            <section className='sustainability-commitment'>
                <h2>Sustainability Commitment</h2>
                <p>
                    We are committed to sustainability and are continuously exploring eco-friendly materials and practices in our product offerings and operations. Our goal is to contribute to a greener future while maintaining the high standards of quality and design that our customers expect.
                </p>
            </section>
        </div>
    );
}

export default AboutUs;
