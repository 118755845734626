import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import './component.css'

const HeadingWithSpace = ({ headingTitle, subHeading, buttonBool, link }) => {
    return (
        <div>
            <div className="heading-title-div--component">
                <div className="headingtitle-holder">
                    <p className="heading-title--component">{headingTitle}</p>
                    <p className="heading-title--component-01">{subHeading}</p>
                </div>
                {
                    buttonBool && <button className="view-all--component" onClick={() => window.location.href = `${link}`}>
                        View All <FaArrowRight />
                    </button>
                }

            </div>
        </div>
    )
}

export default HeadingWithSpace
