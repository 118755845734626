import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { auth } from './config/firebase.config';
import Loading from './component/Loading';
import Navbar from './component/navbar/navbar';
import Footer from './component/navbar/footer';

const Protected = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
                navigate('/login');
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [navigate]);

    if (loading) {
        return <Loading text='LOADING STORE ...' />;
    }

    return user ? (
        <>
            <Navbar />
            <Outlet />
            <Footer />
        </>) : null;
};

export default Protected;
