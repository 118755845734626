import React, { useEffect, useState, useRef } from 'react';
import { BiCaretLeft, BiCaretRight } from 'react-icons/bi';
import useWindowSize from './useWindowSize';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import { auth } from '../config/firebase.config';
import { ToastContainer, toast } from 'react-toastify';
import { db } from '../config/firebase.config';
import { v4 } from 'uuid';
import 'react-toastify/dist/ReactToastify.css';
import './component.css'

const ProductComponent = ({ productArray }) => {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(0);
    const slidesRef = useRef([]);
    const [itemsPerSlide, setItemsPerSlide] = useState(4);
    const gap = 20;
    const size = useWindowSize();
    const isMobile = size.width < 786;


    useEffect(() => {
        console.log('Product Array:', productArray);
    }, [productArray]);

    useEffect(() => {
        const updateItemsPerSlide = () => {
            if (window.innerWidth <= 768) {
                setItemsPerSlide(3);
            } else {
                setItemsPerSlide(4);
            }
        };

        updateItemsPerSlide();
        window.addEventListener('resize', updateItemsPerSlide);

        return () => {
            window.removeEventListener('resize', updateItemsPerSlide);
        };
    }, []);

    useEffect(() => {
        slidesRef.current.forEach((slide, index) => {
            if (slide) {
                slide.style.left = `${index * (100 / itemsPerSlide)}%`;
            }
        });
    }, [counter, itemsPerSlide, productArray]);

    useEffect(() => {
        slideImage();
    }, [counter, itemsPerSlide]);

    const slideImage = () => {
        slidesRef.current.forEach((slide) => {
            if (slide) {
                slide.style.transform = `translateX(-${counter * (100 + gap / itemsPerSlide)}%)`;
                slide.style.transition = 'transform 0.5s ease';
            }
        });
    };

    const goPrev = () => {
        if (counter > 0) {
            setCounter(counter - 1);
        }
    };

    const goNext = () => {
        if (counter < Math.ceil(productArray.length - itemsPerSlide)) {
            setCounter(counter + 1);
        }
    };

    const formatDateTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return {
            orderDate: `${day}/${month}/${year}`,
            orderTime: `${hours}:${minutes}:${seconds}`
        };
    };

    const handleBookNow = async (productId, packContent, products) => {
        if (!auth.currentUser) {
            toast.error("You need to be logged in to add items to the cart.");
            navigate('/account')
            return;
        }
        const confirmation = window.confirm("Are you sure you want to book this product?");
        if (!confirmation) return;

        const { orderDate, orderTime } = formatDateTime(new Date());

        const orderData = {
            customerName: auth.currentUser.displayName,
            uid: auth.currentUser.uid,
            productId: productId,
            orderIdUnique: v4(),
            email: auth.currentUser.email,
            orderQuantity: 1 * packContent,
            purchaseType: 'packs',
            orderDate: orderDate, // store date in dd/mm/yyyy 
            orderTime: orderTime,
            status: 'In Review',
            noOfPacks: 1,
            noOfBoxes: 0,
            ...products
        };

        try {
            await addDoc(collection(db, "orders"), orderData);
            toast.success("Order placed successfully!");
        } catch (error) {
            console.error("Error placing order: ", error);
            toast.error("Failed to place order. Please try again.");
        }
    };

    return (
        <>
            {isMobile ? (
                <div className='productPostin'>
                    <div className='product-container-to-grid-mobile' style={{ position: 'relative' }}>
                        {productArray?.map((product, index) => (
                            <div
                                key={index}
                                className="product-container--wrapper-mobile"
                                onClick={() => navigate(`product/${product.barcode}`)}
                            >
                                <section className="product--wrapper-grid-1-mobile" >
                                    <img
                                        src={product.featuredImageUrl} alt={product.barcode} />
                                </section>
                                <section className="product--wrapper-grid-2">
                                    <div className="product-title--componentfs">{product.productName}</div>
                                    <div className="product-functions--componentfs">

                                        <button className="booknow--fast-component" onClick={() => navigate(`product/${product.id}`)}>Book Now</button>
                                    </div>
                                </section>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className='productPostin'>
                    <button onClick={goPrev} className='positionButtonsMove leftDecrease'><BiCaretLeft /></button>
                    <button onClick={goNext} className='positionButtonsMove rightIncrease'><BiCaretRight /></button>
                    <div className='product-container-to-slide' style={{ position: 'relative', overflow: 'hidden' }}>
                        {productArray?.map((product, index) => (
                            <div
                                key={index}
                                ref={(el) => (slidesRef.current[index] = el)}
                                className="product-container--wrapper"
                                style={{
                                    position: 'absolute',
                                    width: `calc(${100 / itemsPerSlide}% - ${gap}px)`,
                                    marginRight: `${gap}px`
                                }}
                            >
                                <section className="product--wrapper-grid-1" >
                                    <img src={product.featuredImageUrl} alt={product.productName} onClick={() => navigate(`/product/${product.barcode}`)} />
                                </section>
                                <section className="product--wrapper-grid-2">
                                    <div className="product-title--componentfs" onClick={() => navigate(`/product/${product.barcode}`)}>{product.productName}</div>
                                    <div className="product-functions--componentfs">

                                        <button className="booknow--fast-component" onClick={() => handleBookNow(product.id, product.packQuantity, product)} > Book Now</button>
                                    </div>
                                </section>
                            </div>
                        ))}
                    </div >
                </div >
            )}
            <ToastContainer />
        </>
    );
};

export default ProductComponent;
