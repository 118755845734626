import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { auth } from './config/firebase.config';
import Loading from './component/Loading';
import Navbar from './component/navbar/navbar';
import Footer from './component/navbar/footer';

const NavContainerRouter = () => {
    const [user, setUser] = useState(true);



    return user ? (
        <>
            <Navbar />
            <Outlet />
            <Footer />
        </>) : null;
};

export default NavContainerRouter;
