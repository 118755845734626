import React from 'react'
import './component.css'
import HeadingWithSpace from './headingWithSpace'
import ProductComponent from './product'

const ProductHomePage = ({ headingTitle, subHeading, buttonBool, link, productArray }) => {
    return (
        <div>
            <HeadingWithSpace headingTitle={headingTitle} subHeading={subHeading} buttonBool={buttonBool} link={link} />
            <div className="product-slider--home">

                <ProductComponent productArray={productArray} />
            </div>
        </div>
    )
}

export default ProductHomePage
