import React, { useState, useEffect } from 'react';
import Navbar from '../component/navbar/navbar';
import authimg from './../assets/pic3.png';
import './auth.css';
import { auth } from '../config/firebase.config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isMobile, setIsMobile] = useState(false);

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const notifyLoginSuccess = () => toast.success("Welcome, you are logged in!");
    const notifyLoginError = (errorMessage) => toast.error(errorMessage);
    const notifyEmailNotVerified = () => toast.error("Email not verified. Please check your inbox.");

    const handleLogin = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            if (user.emailVerified) {
                notifyLoginSuccess();
                console.log('User Logged In');
                setTimeout(() => {
                    navigate('/');
                }, 2000); // 2-second delay
            } else {
                notifyEmailNotVerified();
            }
        } catch (error) {
            console.error('Error logging in:', error);
            notifyLoginError(`Failed to log in. Error Code: ${error.code}`);
        }
    }

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    return (
        <>
            <Navbar />
            <div className="auth-page-component">
                {isMobile ? (
                    <div className="end-side-content">
                        <form className="auth-component-wrapper" onSubmit={(e) => e.preventDefault()}>
                            <h1>Login</h1>
                            <p className='welcomeTextAuth'>Welcome, please login to your account</p>
                            <label>
                                Email Address
                                <input type="text" value={email} onChange={handleEmailChange} />
                            </label>
                            <label>
                                Password
                                <input type="password" value={password} onChange={handlePasswordChange} />
                            </label>
                            <div className="display-checkbox-forgetpass">
                                <label id='checkbox-rememberme'>
                                    <input type="checkbox" /> Remember Me
                                </label>
                                <a href="#" id='forgot-password'>Forgot Password</a>
                            </div>
                            <div className="buttongrp-auth">
                                <button type="button" id='borderBackBlack' onClick={handleLogin}>Login</button>
                                <button type="button" id='nonBorderBackWhite' onClick={() => navigate('/signup')}>Register</button>
                            </div>
                        </form>
                    </div>
                ) : (
                    <>
                        <div className="left-auth-page start-side-content">
                            <div className="breadcrumb-leftauth">
                                <h1>My Account</h1>
                                <p>Home {'>'} My Account</p>
                            </div>
                            <div className="img-auth">
                                <img src={authimg} alt="Auth" />
                            </div>
                        </div>
                        <div className="end-side-content">
                            <form className="auth-component-wrapper" onSubmit={(e) => e.preventDefault()}>
                                <h1>Login</h1>
                                <p className='welcomeTextAuth'>Welcome, please login to your account</p>
                                <label>
                                    Email Address
                                    <input type="text" value={email} onChange={handleEmailChange} />
                                </label>
                                <label>
                                    Password
                                    <input type="password" value={password} onChange={handlePasswordChange} />
                                </label>
                                <div className="display-checkbox-forgetpass">
                                    <label id='checkbox-rememberme'>
                                        <input type="checkbox" /> Remember Me
                                    </label>
                                    <a href="#" id='forgot-password'>Forgot Password</a>
                                </div>
                                <div className="buttongrp-auth">
                                    <button type="submit" id='borderBackBlack' onClick={handleLogin}>Login Now</button>
                                    <button type="button" id='nonBorderBackWhite' onClick={() => navigate('/signup')}>Register</button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </div>
            <ToastContainer />
        </>
    );
};

export default Login;
