import React, { useState, useEffect, useRef } from 'react';
import './navbar.css';
import './../../index.css';
import chatbot from './../../assets/chat-bot-100x100.png';
import logo from './../../assets/LOGO CURSIVE rectangular-02.png';
import { FaCaretDown, FaSearch } from 'react-icons/fa';
import { IoSearchOutline, IoCartOutline } from 'react-icons/io5';
import { HiBars3, HiOutlineShoppingCart, HiOutlineUserCircle } from 'react-icons/hi2';
import { BsFacebook, BsInstagram, BsTwitterX, BsWhatsapp } from 'react-icons/bs';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../config/firebase.config';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, where, query } from 'firebase/firestore';
import { IoMdClose } from 'react-icons/io';

const Navbar = () => {
    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('All Categories');
    const [menuOpen, setMenuOpen] = useState(false);
    const [openItems, setOpenItems] = useState({});
    const [authenticated, setAuthenticated] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [categories, setCategory] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const menuRef = useRef(null);
    const menu = [
        {
            item: 'HOME',
            url: '/',

        },
        {
            item: 'SHOP', url: '/shop',
            //  subItem: [
            //     { item: 'Sub 1', url: '/' },
            //     { item: 'Sub Us', url: '/' },
            //     { item: 'Sub 2', url: '/' },
            // ],
        },
        {
            item: 'CONTACT US', url: '/contact-us'
        }, {
            item: 'ABOUT US', url: '/about-us'
        },
        { item: 'ACCOUNT', url: '/account' },

    ];


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthenticated(true);
            }
        });

        const fetchCategory = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'categories'));
                const categoryData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCategory(categoryData);
            } catch (error) {
                console.error("Error fetching categories: ", error);
            }
        };

        fetchCategory();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        console.log(e.target.value);
    };

    useEffect(() => {
        const fetchProductSuggestions = async () => {
            if (searchTerm.trim() === '') {
                setSuggestions([]);
                return;
            }

            const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();
            console.log('Search Term: ' + lowerCaseSearchTerm);

            try {
                const productsRef = collection(db, 'products');
                const q = query(productsRef);
                const querySnapshot = await getDocs(q);
                let allProducts = [];

                querySnapshot.forEach((doc) => {
                    allProducts.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                console.log('All Products: ', allProducts);

                // Filter products based on the lowercase search term
                const filteredSuggestions = allProducts.filter(product =>
                    product.productName.toLowerCase().includes(lowerCaseSearchTerm)
                );

                console.log('Filtered Suggestions: ', filteredSuggestions);

                setSuggestions(filteredSuggestions);
            } catch (error) {
                console.error("Error fetching product suggestions: ", error);
            }
        };

        fetchProductSuggestions();
    }, [searchTerm]);


    const handleSuggestionClick = (productId) => {
        navigate(`/product/${productId}`);
        setSearchTerm('');
        setSuggestions([]);
    };



    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const toggleSubMenu = (item) => {
        setOpenItems((prevState) => ({
            ...prevState,
            [item]: !prevState[item],
        }));
    };

    const handleCategoryClick = (categoryName) => {
        navigate(`/shop/${categoryName}`);
        setDropdownVisible(false);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        return () => {
            setOpenItems({});
        };
    }, []);

    return (
        isMobile ? (
            <>
                <div className="navbar-top-mobile">
                    <div className="hamburger-menu">
                        <button onClick={() => setMenuOpen(!menuOpen)}>
                            {menuOpen ? <IoMdClose /> : <HiBars3 />}
                        </button>
                    </div>
                    <div className="logo-mobile navbar-mobileloggo">
                        <img src={logo} alt="logo" onClick={() => { navigate('/') }} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="iconsNavbar-mobile">
                        <a href="/cart">
                            <HiOutlineShoppingCart />
                        </a>
                        <a href="/account">
                            <HiOutlineUserCircle />
                        </a>
                    </div>
                </div>
                <div ref={menuRef} className={`mobile-side-menu ${menuOpen ? 'open' : ''}`}>
                    <div className="logo-mobile" style={{ cursor: 'pointer' }}>
                        <img src={logo} alt="logo" />
                    </div>
                    <ul>
                        {menu?.map((menuItem) => (
                            <li key={menuItem.item} onClick={(e) => {
                                if (menuItem.subItem) {
                                    e.preventDefault();
                                    toggleSubMenu(menuItem.item);
                                }
                            }}>
                                <div className="container--a---mobile-menu">
                                    <a
                                        href={`${menuItem.subItem ? '' : menuItem.url}`}
                                    >{menuItem.item}</a>
                                    {menuItem.subItem && <button className='facaretdown--mobile-menu'>
                                        <FaCaretDown />
                                    </button>}
                                </div>
                                <hr />
                                {menuItem.subItem && (
                                    <ul style={{ display: openItems[menuItem.item] ? 'block' : 'none' }}>
                                        {menuItem.subItem?.map((subItem) => (
                                            <li key={subItem.item} className='mobileSubMenu--1'>
                                                <a href={subItem.url} className='subItem-item--mobile'>{subItem.item}</a>
                                            </li>
                                        ))}
                                        <hr />
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                    <div className="social-icons-mobilemeni">
                        <div className="icon-social--child">
                            <BsTwitterX />
                        </div>
                        <div className="icon-social--child">
                            <BsFacebook />
                        </div>
                        <div className="icon-social--child">
                            <BsWhatsapp />
                        </div>
                        <div className="icon-social--child">
                            <BsInstagram />
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <>
                <div className="center-div" style={{ background: 'var(--primary-background-color)', flexDirection: 'column' }}>
                    <div className="navbar-top-container">
                        <div className="logo-container">
                            <img src={logo} alt="Logo" onClick={() => { navigate('/') }} style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="search-support-navbar">
                            <div className="category-product-support">
                                <div className="product-search-input-navbar">
                                    <button className="psin-category-dropdown" onClick={toggleDropdown}>
                                        <p className="categoryMainDropdown--psin">{selectedCategory}</p>
                                        <span><FaCaretDown /></span>
                                    </button>
                                    {dropdownVisible && (
                                        <div className="dropdown-menu">
                                            {categories?.map((category) => (
                                                <a
                                                    key={category.id}
                                                    href="#"
                                                    onClick={() => handleCategoryClick(category.categoryId)}
                                                >
                                                    {category.categoryName}
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                    <div className="inputproduct-div--search">
                                        <input
                                            type="text"
                                            className="input--product-search"
                                            placeholder="Search Products"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                        />
                                        <button className="faSearchHAbsolute"><FaSearch /></button>
                                        {suggestions.length > 0 && (
                                            <div className="suggestions-dropdown">
                                                {suggestions.map((product) => (
                                                    <div
                                                        key={product.id}
                                                        className="suggestion-item"
                                                        onClick={() => handleSuggestionClick(product.sku)}
                                                    >
                                                        {product.productName}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="support-icon-andtext">
                            {authenticated ? (
                                <div className="support-icon-andtext">
                                    <img src={chatbot} alt="Chatbot" />
                                    <div className="textpart-spic">
                                        <span className="textPart-247-spic">Welcome Back!</span>
                                        <span className="titledHere-spic">{auth?.currentUser?.displayName}</span>
                                    </div>
                                </div>
                            ) : (
                                <button onClick={() => window.location.href = '/login'}>Login</button>
                            )}
                        </div>
                    </div>
                </div >
                <div className="navbar-bottom">
                    <div className="navbar-bottom-left">
                        <button className="categories-fetch-show" style={{ cursor: 'pointer' }} onClick={() => navigate('/category')}>
                            <HiBars3 />
                            BROWSE ALL CATEGORIES
                        </button>
                        <div className="menu-items-navigation">
                            {menu?.map((menuItem) => (
                                <div className={`menu-item`} key={menuItem.item}>
                                    <a href={menuItem.url} className={`main-menu-link ${menuItem.subItem && `menu-item-drop`}`}>{menuItem.item}</a>
                                    {menuItem.subItem && (
                                        <div className="sub-menu">
                                            {menuItem.subItem?.map((subItem) => (
                                                <a href={subItem.url} key={subItem.item}>{subItem.item}</a>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="navbar-bottom-right">
                        <a href="/shop">
                            <IoSearchOutline />
                        </a>
                        <a href="/cart">
                            <IoCartOutline />
                        </a>
                        <a href="/account">
                            <HiOutlineUserCircle />
                        </a>
                    </div>
                </div>
            </>
        )
    );
};

export default Navbar;
