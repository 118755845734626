import React from 'react'
import './route.css'
import { BiPhoneCall } from 'react-icons/bi'
import { CiLocationOn } from 'react-icons/ci'
import { MdEmail } from 'react-icons/md'
import { BsWhatsapp } from 'react-icons/bs'

const ContactUs = () => {
    return (
        <div className='contactus-container'>
            <section>
                <h1 className='h1Contact'>
                    For bulk purchases of the items or single items, please connect with us directly
                </h1>

                <div className="contactus-container--widgets">
                    <div className="widgetcontactus-child">
                        <div className="widgetcontactus-child1">
                            <CiLocationOn /> &nbsp;
                            Address
                        </div>
                        <div className="widgetcontactus-child2">
                            Sales Office: Cursive_Letters_LY ( A unit of Shuddharth India Pvt Ltd)
                            930A, Tower B3, SpazeItech Park
                            Gurgaon 122002 , Haryana , India
                        </div>
                    </div>
                    <hr />
                    <div className="widgetcontactus-child">
                        <div className="widgetcontactus-child1">
                            <MdEmail />&nbsp;
                            Email Address
                        </div>
                        <div className="widgetcontactus-child2">
                            cursivelettersly@gmail.com                        </div>
                    </div>
                    <hr />
                    <div className="widgetcontactus-child">
                        <div className="widgetcontactus-child1">
                            <BiPhoneCall />&nbsp;
                            Contact
                        </div>
                        <div className="widgetcontactus-child2">
                            +91-90680-63000,  +91-99589-66630                        </div>
                    </div>
                    <hr />
                    <div className="widgetcontactus-child">
                        <div className="widgetcontactus-child1">
                            <BsWhatsapp />&nbsp;
                            Whatsapp Number
                        </div>
                        <div className="widgetcontactus-child2">
                            +91-70272-71077
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="formWidget">
                    <form action="https://formspree.io/f/xvgpqyqk"
                        method="POST">
                        <h1>Want to Talk? Lets do it</h1>

                        <label >
                            <p>Enter Your Name</p>
                            <input type="text" name='FullName' placeholder="Ex: John Doe" />
                        </label>
                        <label >
                            <p>Enter Your Phone Number</p>
                            <input type="tel" id="" name="Phone Number" placeholder='+91 00000 00000' />
                        </label>
                        <label >
                            <p>Enter  Whatsapp Number</p>
                            <input type="tel" id="" name="Whatsapp Number" placeholder='+91 00000 00000' />
                        </label>
                        <label >
                            <p>Enter Your Message</p>
                            <textarea name="Message" id=""></textarea>                        </label>
                        <button type="submit" className='submitContact'>Send Message</button>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default ContactUs
