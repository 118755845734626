import React, { useState, useEffect } from 'react';
import Navbar from '../component/navbar/navbar';
import authimg from './../assets/pic3.png';
import './auth.css';
import { db, auth } from '../config/firebase.config';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router';
import verificationLogo from './../assets/Animation - 1722515296578.gif'

const Signup = () => {
    const navigate = useNavigate();
    const [isPhone, setIsPhone] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkingVerification, setCheckingVerification] = useState(false);

    const notifyLoginSuccess = () => toast.success("Registration Successful");
    const notifyLoginError = (errorMessage) => toast.error(errorMessage);

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleDisplayNameChange = (e) => setDisplayName(e.target.value);
    const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
    const handleOtpChange = (e) => setOtp(e.target.value);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    const handleRegister = async () => {
        setLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Send email verification
            await sendEmailVerification(user);
            toast.success('Check You Email for Verification !!')

            // Update the user's profile with the display name
            await updateProfile(user, { displayName: displayName });

            // Save user details to Firestore
            await setDoc(doc(db, 'userProfile', user.uid), {
                photoUrl: '',
                displayName: displayName,
                username: user.email ? user.email.split('@')[0] : '',
                email: user.email,
                isVerified: true,
                role: 'user',
                phoneNumber: null,
                enquiries: [],
                uid: user.uid,
                password: password,
                cart: []
            });

            setCheckingVerification(true);
            toast.success('Registration Set')
            toast.warn('Waiting for Verification')
            setTimeout(() => {
                checkVerification(user);
            }, 2000);
        } catch (error) {
            console.error('Error creating user:', error);
            notifyLoginError(`Failed to Register. Error Code: ${error.code}`);
            setLoading(false);
        }
    };

    const checkVerification = async (user) => {
        try {
            await user.reload();
            if (user.emailVerified) {
                setCheckingVerification(false);
                setLoading(false);
                navigate('/');
            } else {
                setTimeout(() => {
                    checkVerification(user);
                }, 3000);
            }
        } catch (error) {
            console.error('Error checking email verification:', error);
        }
    };

    return (
        <>
            <Navbar />

            {
                isMobile ? (
                    <div className="auth-page-component">
                        <div className="end-side-content">
                            <form className="auth-component-wrapper" onSubmit={(e) => e.preventDefault()}>
                                <h1>Create Your Account</h1>
                                <p className='welcomeTextAuth'>Welcome, please login to your account</p>
                                <label>
                                    Display Name
                                    <input type="text" value={displayName} onChange={handleDisplayNameChange} />
                                </label>

                                <label>
                                    Email Address
                                    <input type="text" value={email} onChange={handleEmailChange} />
                                </label>

                                <label>
                                    Password
                                    <input type="password" value={password} onChange={handlePasswordChange} />
                                </label>
                                {loading && (
                                    <div className="verification-message">
                                        <img src={verificationLogo} alt="" style={{ width: '50px', height: '50px' }} />
                                        CHECKING VERIFICATION AND WAITING
                                    </div>
                                )}
                                <div className="display-checkbox-forgetpass">
                                    <label id='checkbox-rememberme'>
                                        <input type="checkbox" /> Remember Me
                                    </label>
                                    <a href="#" id='forgot-password'>Forgot Password</a>
                                </div>
                                <div className="buttongrp-auth">
                                    <button type="button" id='borderBackBlack'>Login</button>
                                    <button type="button" id='nonBorderBackWhite' onClick={handleRegister}>Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div className="auth-page-component">
                        <div className="left-auth-page start-side-content">
                            <div className="breadcrumb-leftauth">
                                <h1>My Account</h1>
                                <p>Home {'>'} My Account</p>
                            </div>
                            <div className="img-auth">
                                <img src={authimg} alt="Auth" />
                            </div>
                        </div>
                        <div className="end-side-content">
                            <form className="auth-component-wrapper" onSubmit={(e) => e.preventDefault()}>
                                <h1>Create Your Account</h1>
                                <p className='welcomeTextAuth'>Welcome, please login to your account</p>
                                <label>
                                    Display Name
                                    <input type="text" value={displayName} onChange={handleDisplayNameChange} />
                                </label>

                                <label>
                                    Email Address
                                    <input type="text" value={email} onChange={handleEmailChange} />
                                </label>

                                <label>
                                    Password
                                    <input type="password" value={password} onChange={handlePasswordChange} />
                                </label>
                                {loading && (
                                    <div className="verification-message">
                                        <img src={verificationLogo} alt="" style={{ width: '50px', height: '50px' }} />
                                        CHECKING VERIFICATION AND WAITING
                                    </div>
                                )}
                                <div className="display-checkbox-forgetpass">
                                    <label id='checkbox-rememberme'>
                                        <input type="checkbox" /> Remember Me
                                    </label>
                                    <a href="#" id='forgot-password'>Forgot Password</a>
                                </div>
                                <div className="buttongrp-auth">
                                    <button type="button" id='borderBackBlack'>Login</button>
                                    <button type="button" id='nonBorderBackWhite' onClick={handleRegister}>Create Account</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            }
            <ToastContainer />
        </>
    );
};

export default Signup;
