import React, { useEffect, useState } from 'react'
import Navbar from '../component/navbar/navbar'
import SliderJsx from '../component/slider'
import { CategoryHome } from '../component/categories'
import './route.css'
import ProductHomePage from '../component/productHomePage'
import useWindowSize from '../component/useWindowSize'
import HeadingWithSpace from '../component/headingWithSpace'
import MostWanted from '../component/mostWanted'
import WallOfFame from '../component/walloffame'
import ProductGrid from '../component/product_style/productGrid'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../config/firebase.config'

const Home = () => {
    const [products, setProducts] = useState([]);
    const [categoryD, setCategory] = useState([]);
    const [newArrivals, setNewArrivals] = useState([])

    const size = useWindowSize()
    const isMobile = size.width < 768;
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'products'));
                const productsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setProducts(productsData);
                console.log(productsData);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };
        const fetchProductsTags = async () => {
            try {
                // Log to confirm the function is called
                console.log("Fetching products with tag 'new'...");

                // Create the query to fetch only products with the 'new' tag
                const q = query(collection(db, 'products'), where('productTags', 'array-contains', 'new'));

                // Execute the query and get the snapshot
                const querySnapshot = await getDocs(q);

                // Check if documents were returned
                if (querySnapshot.empty) {
                    console.log("No products with the 'new' tag found.");
                    setProducts([]);  // Set empty array if no products are found
                    return;
                }

                // Map through the documents and create the productsData array
                const productsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Set the products state with the fetched data
                setNewArrivals(productsData);

                // Log the fetched products data
                console.log("Fetched products:", productsData);
            } catch (error) {
                // Handle any errors that occur during the fetch
                console.error("Error fetching products: ", error);
            }
        };
        const fetchCategory = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'categories'));
                const categoryData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCategory(categoryData);
                console.log(categoryData);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };

        fetchCategory();
        fetchProductsTags()
        fetchProducts();
    }, []);

    //categories
    const categoryArray = [
        {
            categoryName: 'Money Bank',
            categoryImg: 'https://res.cloudinary.com/joinventures/image/upload/banners/home_hacks_propshop24_lenses_20230514.png',
            categoryId: '75248'
        },
        {
            categoryName: 'Pencil Box',
            categoryImg: 'https://res.cloudinary.com/joinventures/image/upload/banners/organisers_storage_propshop24_lenses_20230514.png',
            categoryId: '75248'
        },
        {
            categoryName: 'Pencil Pouch EVA',
            categoryImg: 'https://res.cloudinary.com/joinventures/image/upload/banners/kitchen_dining_propshop24_lenses_20230514.png',
            categoryId: '75248'
        },
        {
            categoryName: 'Water Bottle',
            categoryImg: 'https://res.cloudinary.com/joinventures/image/upload/banners/lights_lamps_propshop24_lenses_20230514.png',
            categoryId: '75248'
        },
        {
            categoryName: 'Pens',
            categoryImg: 'https://res.cloudinary.com/joinventures/image/upload/banners/lunch_boxes_d_lenses_1666_20240212181524.jpg',
            categoryId: '75248'
        }
    ];



    return (
        <div>
            <SliderJsx />
            <img
                src={
                    isMobile ? 'https://res.cloudinary.com/joinventures/image/upload/banners/brand_promise_m_ps24_illustration_20230615.jpg' : 'https://res.cloudinary.com/joinventures/image/upload/banners/brandpromise_d_ps24_illustration_20230609.jpg'
                }
                alt="" style={{ width: '100%', margin: '24px 0px' }} />


            <div className="category0-home0-container">
                <CategoryHome categoryArray={categoryD} />
            </div>
            <div className="producthomepage">
                <ProductHomePage productArray={newArrivals} tags='Tags' buttonBool={true} link='/shop' headingTitle='Explore New Arrivals' subHeading='Discover our exciting New Arrivals' />
            </div>

            {/* <img src={
                isMobile ? 'https://res.cloudinary.com/joinventures/image/upload/banners/express_shipping_m_illustration_1666_20240122103519.gif' : 'https://res.cloudinary.com/joinventures/image/upload/banners/express_shipping_d_illustration_1666_20240212181657.jpg'
            }
                alt="" style={{ width: '100%', margin: '10px 0px 20px 0px' }} /> */}

            {/* <div className="producthomepage">
                <ProductHomePage productArray={products} buttonBool={true} link='/shop' headingTitle='Trending This Week' subHeading='Tried, tested, and loved by our customers' />
            </div> */}


            {/* <img src={
                isMobile ? 'https://res.cloudinary.com/joinventures/image/upload/banners/illustration_brandstory_m_propshop24_140623.jpg' : 'https://res.cloudinary.com/joinventures/image/upload/banners/illustration_brandstory_d_propshop24_260523.jpg'
            } alt="" style={{ width: '100%', marginBottom: '20px' }} /> */}
            <HeadingWithSpace headingTitle='About Us' subHeading='Know More Now' buttonBool={false} />

            <img
                src={
                    isMobile ? 'https://res.cloudinary.com/joinventures/image/upload/banners/Brand-Intro-Mobile-version-2.jpg' : 'https://res.cloudinary.com/joinventures/image/upload/banners/illustration_brand_intro_d_propshop24_26062023.jpg'
                }
                alt="" style={{ width: '100%', marginBottom: '20px' }} />
            {/* <HeadingWithSpace headingTitle='Most Wanted' subHeading='Collection that are winning hearts' buttonBool={false} /> */}
            {/* <MostWanted /> */}
            {/* <img src={isMobile ? 'https://res.cloudinary.com/joinventures/image/upload/banners/luxe_illustration_d_illustration_1666_20230901191809.jpg' : 'https://res.cloudinary.com/joinventures/image/upload/banners/luxe_illustration_d_illustration_1666_20230901191809.jpg'} style={{ width: '100%' }} alt="" /> */}
            <HeadingWithSpace headingTitle='Check Out Our Products' subHeading="Here are all the arrivals & innovations" buttonBool={false} />

            <ProductGrid productArray={products} />

            <img
                src={isMobile ? 'https://res.cloudinary.com/joinventures/image/upload/banners/clearance_sale_m_illustration_20230514.gif' : 'https://res.cloudinary.com/joinventures/image/upload/banners/clearancesale_d_ps24_illustration_20230609.gif'}
                alt="" style={{ width: '100%' }} />
            {/* <WallOfFame /> */}
        </div>
    )
}

export default Home