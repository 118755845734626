// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBbbwqxg83BqHN3NaxNc3datwIqP4tfSmc",
    authDomain: "cursiveletter-e3053.firebaseapp.com",
    projectId: "cursiveletter-e3053",
    storageBucket: "cursiveletter-e3053.appspot.com",
    messagingSenderId: "915915674239",
    appId: "1:915915674239:web:5dc1a949a6815ece36254a",
    measurementId: "G-5MQGZQZ3KP"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app)


export { auth, db, storage };